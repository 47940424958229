@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
}
  
@font-face {
  font-family: 'Lora';
  src: url('https://fonts.googleapis.com/css?family=Lora&display=swap');
}

@font-face {
  font-family: 'Righteous';
  src: url('https://fonts.googleapis.com/css?family=Righteous&display=swap');
}